


import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import PageTitle from '@/_components/page-title/page-title.vue';
import TicketListItem from '@/_modules/tickets/components/ticket-list-item/ticket-list-item.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {TDeleteTicketRequestParams, TGetTicketsRequestParams, TTicket} from '@/_api/tickets.api';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {TEvent} from '@/_types/event.type';
import ModuleHasNoItems from '@/_components/module-has-no-items/module-has-no-items.vue';
import IconTickets from '@/_modules/icons/components/sidebar/icon-tickets.vue';

@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    SearchBar,
    PageTitle,
    TicketListItem,
    SimplePopup,
    EwButton,
    ModuleHasNoItems,
    IconTickets,
  }
})
export default class TicketList extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('ticketsStore/tickets') tickets: TTicket[];
  @Getter('ticketsStore/isConfirmTicketDeletionPopupVisible') isConfirmTicketDeletionPopupVisible: boolean;
  @Getter('ticketsStore/isLoading') isTicketsLoading: boolean;
  @Getter('ticketsStore/singleTicket') singleTicket: TTicket;

  @Action('ticketsStore/requestTickets') requestTickets: (params: TGetTicketsRequestParams) => Promise<TTicket[]>;
  @Action('ticketsStore/showDeletionConfirm') showTicketDeletionConfirm: () => void;
  @Action('ticketsStore/hideDeletionConfirm') hideTicketDeletionConfirm: () => void;
  @Action('ticketsStore/deleteTicket') deleteTicket: (params: TDeleteTicketRequestParams) => Promise<void>;

  public ticketIdToDelete: number = null;

  @Prop({type: Number, default: null})
  private showOnlyTicketWithId: number;

  @Prop({type: Boolean, default: false})
  private singleTicketMode: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get ticketsToShow(): TTicket[] {
    if (this.singleTicketMode) {
      return [this.singleTicket || null].filter(x => x);
    }

    if (!this.showOnlyTicketWithId) {
      return this.tickets;
    }

    return (this.tickets || []).filter(ticket => ticket.id === this.showOnlyTicketWithId);
  }

  public created(): void {
    this.requestTickets({eventId: this.eventId});
  }

  public onTicketListItemClick(ticketId: number): void {
    this.$router.push({
      name: 'view-ticket',
      params: {
        ticketId: ticketId.toFixed(0),
      }
    }).catch(() => { /* ignore */ });
  }

  public onDeleteTicket(ticketId: number): void {
    this.showTicketDeletionConfirm();
    this.ticketIdToDelete = ticketId;
  }

  public onCloseConfirmTicketDeletionPopupClick(): void {
    this.hideTicketDeletionConfirm();
    this.ticketIdToDelete = null;
  }

  public isTicketDeletionInProgress: boolean = false;

  public async onConfirmDeletionClick(): Promise<void> {
    if (!this.ticketIdToDelete) {
      return;
    }
    this.isTicketDeletionInProgress = true;
    await this.deleteTicket({ticketId: this.ticketIdToDelete});
    this.isTicketDeletionInProgress = false;
    this.hideTicketDeletionConfirm();
    if (this.$route.name !== 'event-tickets') {
      await this.$router.push({name: 'event-tickets'});
    }
  }

  public onAddNewTicketButtonClick(): void {
    this.$router.push({
      name: 'create-ticket',
    })
  }
}
