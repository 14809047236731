


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import EwInput from '@/_modules/standalone-company/components/UI/ew-input/ew-input.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import ticketsApi, {TAddTicketRequestParams, TGetTicketsRequestParams, TTicket} from '@/_api/tickets.api';
import SwitchCheckbox from '@/views/components/parts/switch-checkbox.vue';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TFile} from '@/_types/file.type';
import FileHelper from '@/_helpers/file.helper';
import {TEvent} from '@/_types/event.type';
import DatepickerHelper, {TMuseUIDatepickerDateTimeFormat} from '@/_helpers/datepicker.helper';

export const PLAIN_TEXT_TICKET_PREFIX = 'EW_TEXT_TICKET:'; // TODO: phase it out
export const PLAIN_TEXT_TICKET_MAX_LENGTH = 64;

@Component({
  components: {
    SwitchCheckbox,
    EwInput,
    EwButton,
    FormFileUploader,
  }
})
export default class CreateTicket extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('ticketsStore/tickets') tickets: TTicket[];
  @Getter('ticketsStore/ticketsLastUpdatedTimestamp') ticketsLastUpdated: number;
  @Action('ticketsStore/requestTickets') requestTickets: (params: TGetTicketsRequestParams) => Promise<TTicket[]>;

  public localTicket: TAddTicketRequestParams = {
    event_id: 0,
    file_url: '',
    title: '',
    date_start: '',
    date_end: '',
    data: '',
  }

  public plainText: string = '';
  public readonly plainTextMaxLength: number = PLAIN_TEXT_TICKET_MAX_LENGTH;

  public isOptionalFieldsVisible: boolean = false;
  public localTicketFileUploadResult: TFile = {
    filename: '',
    url: '',
  };
  public fileUrlMode: 'file' | 'url' | 'plainText' = 'file';
  public rawDateStart: Date = null;
  public rawDateEnd: Date = null;

  public isReady: boolean = false;

  @Watch('event', {immediate: true})
  private onEventChange(newVal: TEvent): void {
    if (newVal) {
      this.init();
    }
  }

  public init(): void {
    this.rawDateStart = this.event.date_start;
    this.rawDateEnd = this.event.date_end;
    this.isReady = true;
  }

  public get remainingPlainTextLength(): string {
    return (PLAIN_TEXT_TICKET_MAX_LENGTH - this.plainText.length).toString(10);
  }

  public get isLocalTicketFileImage(): boolean {
    if (!this.localTicketFileUploadResult) {
      return false;
    }
    return FileHelper.isImage((this.localTicketFileUploadResult && this.localTicketFileUploadResult.url) || '');
  }

  public get localTicketFileExtension(): string {
    return FileHelper.getFileExtension((this.localTicketFileUploadResult && this.localTicketFileUploadResult.url) || '') || 'file';
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isCurrentFileUrlModeFile(): boolean {
    return this.fileUrlMode === 'file';
  }

  public get isCurrentFileUrlModeUrl(): boolean {
    return this.fileUrlMode === 'url';
  }

  public get isCurrentFileUrlModePlainText(): boolean {
    return this.fileUrlMode === 'plainText';
  }

  public get isFileUrlExisting(): boolean {
    if (this.isCurrentFileUrlModeFile) {
      return false;
    }

    if (this.isCurrentFileUrlModeUrl) {
      const isPresentInGetterTickets = !!(this.tickets && this.tickets.find(t => t.file_url === (this.localTicket.file_url || '')));
      const isPresentInExistingTickets = !!(this.existingTickets && this.existingTickets.find(t => t.file_url === (this.localTicket.file_url || '')))
      return isPresentInGetterTickets || isPresentInExistingTickets;
    }

    const isPresentInGetterTickets = !!(this.tickets && this.tickets.find(t => t.file_url === this.plainText || ''));
    const isPresentInExistingTickets = !!(this.existingTickets && this.existingTickets.find(t => t.file_url === this.plainText))
    return isPresentInGetterTickets || isPresentInExistingTickets;
  }

  public isSubmitting: boolean = false;

  public get isSubmissionDisabled(): boolean {
    if (this.isCurrentFileUrlModeFile) {
      return !this.localTicketFileUploadResult.url || this.isSubmitting;
    }
    if (this.isCurrentFileUrlModePlainText) {
      return !this.plainText || this.isSubmitting || this.isFileUrlExisting;
    }
    return !this.localTicket.file_url || this.isSubmitting || this.isFileUrlExisting;
  }

  public errorText: string = '';

  public hideError(): void {
    this.errorText = '';
  }

  public showError(): void {
    this.errorText = '';
  }

  public existingTickets: TTicket[] = null;

  public async onSubmit(): Promise<boolean> {
    if (this.isSubmitting) {
      return false;
    }

    this.hideError();

    if (this.isSubmissionDisabled) {
      this.showError();
      return false;
    }

    this.isSubmitting = true;

    this.existingTickets = await ticketsApi.getTickets({ eventId: this.eventId, limit: 1000, offset: 0 });
    if (this.existingTickets && this.existingTickets.find(t => t.file_url === this.localTicket.file_url)) {
      this.isSubmitting = false;
      return false;
    }

    this.localTicket.event_id = this.eventId;

    if (this.isCurrentFileUrlModeFile) {
      this.localTicket.file_url = this.localTicketFileUploadResult.url;
    }

    if (this.isCurrentValidityModeCustom) {
      this.localTicket.date_start = this.dateToTicketApiDate(this.rawDateStart);
      this.localTicket.date_end = this.dateToTicketApiDate(this.rawDateEnd);
    } else {
      this.localTicket.date_start = null;
      this.localTicket.date_end = null;
    }

    this.localTicket.data = JSON.stringify({
      by: 'SPA create-ticket v1',
      created_on: (new Date()).toISOString(),
    });

    let result: TTicket = null;
    try {
      result = await ticketsApi.addTicket({
        ...this.localTicket,
        file_url: this.isCurrentFileUrlModePlainText ? this.plainText.substring(0, PLAIN_TEXT_TICKET_MAX_LENGTH) : this.localTicket.file_url,
      });
    } catch {
      /* ignore */
    }

    if (result && result.id) {
      this.$router.push({
        name: 'view-ticket',
        params: {
          ticketId: result.id.toFixed(0),
        }
      }).catch(() => { /* ignore */ });
    }

    this.isSubmitting = false;

    return false;
  }

  public dateToTicketApiDate(date: Date): string {
    return this.stupidlyCutOffSecondsAndZ(date.toISOString());
  }

  public stupidlyCutOffSecondsAndZ(input: string): string {
    // Backend route fails if ticket dates contain seconds and milliseconds... Must be some reason for that
    return input.replace(/:\d{2}\.\d{3}Z$/, '');
  }

  public setFileUrlMode(newMode: 'file' | 'url' | 'plainText'): void {
    this.fileUrlMode = newMode;
  }

  public isTicketFileUploading: boolean = false;

  public onTicketFileUploaderIdle(): void {
    this.isTicketFileUploading = false;
  }

  public onTicketFileUploaderUploading(): void {
    this.isTicketFileUploading = true;
  }

  public ticketValidityMode: 'wholeEvent' | 'custom' = 'wholeEvent';

  public setValidityMode(newMode: 'wholeEvent' | 'custom'): void {
    this.ticketValidityMode = newMode;
  }

  public get isCurrentValidityModeWholeEvent(): boolean {
    return this.ticketValidityMode === 'wholeEvent';
  }

  public get isCurrentValidityModeCustom(): boolean {
    return this.ticketValidityMode === 'custom';
  }

  public getCalendarDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {
    return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
  }

  public firstDayOfWeek(): number {
    return DatepickerHelper.getFirstDayOfWeekNumber();
  }

  public onDuplicateTicketErrorClick(): void {
    if (!this.isFileUrlExisting) {
      return;
    }
    const existingTicket = ((this.tickets && this.tickets.find(t => t.file_url === this.localTicket.file_url)) || null)
      || ((this.existingTickets && this.existingTickets.find(t => t.file_url === this.localTicket.file_url)) || null);
    if (!existingTicket || !existingTicket.id) {
      return;
    }
    this.$router.push({
      name: 'view-ticket',
      params: {
        ticketId: existingTicket.id.toFixed(0),
      }
    }).catch(() => { /* ignore */ });
  }

  public onDuplicatePlainTextTicketErrorClick(): void {
    if (!this.isFileUrlExisting) {
      return;
    }

    const existingTicket = ((this.tickets && this.tickets.find(t => t.file_url === this.plainText)) || null)
      || ((this.existingTickets && this.existingTickets.find(t => t.file_url === this.plainText)) || null);
    if (!existingTicket || !existingTicket.id) {
      return;
    }
    this.$router.push({
      name: 'view-ticket',
      params: {
        ticketId: existingTicket.id.toFixed(0),
      }
    }).catch(() => { /* ignore */ });
  }

}
