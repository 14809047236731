


import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {TTicket} from '@/_api/tickets.api';
import {TEvent} from '@/_types/event.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import IconTrashDelete from '@/_modules/icons/components/icon-trash-delete.vue';
import FileHelper from '@/_helpers/file.helper';
import UtilsHelper from '@/_helpers/utils.helper';
import { PLAIN_TEXT_TICKET_PREFIX, PLAIN_TEXT_TICKET_MAX_LENGTH } from '@/_modules/tickets/components/create-ticket/create-ticket.vue';

@Component({
  components: {
    IconTrashDelete,
  }
})
export default class TicketListItem extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  @Prop({type: Object, default: (): TTicket => null })
  private readonly ticket: TTicket;

  @Prop({type: Boolean, default: false})
  private readonly isMarkedForDeletion: boolean;

  @Prop({type: Boolean, default: false})
  private readonly hideAllActions: boolean;

  @Prop({type: Boolean, default: false})
  private readonly hideViewAction: boolean;

  public get ticketTitle(): string {
    return (this.ticket && this.ticket.title) || this.fallbackTicketTitle || (this.$t('untitledTicket') as string);
  }

  public get eventTitle(): string {
    return (this.event && this.event.title) || '';
  }

  public get fallbackTicketTitle(): string {
    return this.$t('fallbackTicketTitle', {eventTitle: this.eventTitle}) as string;
  }

  public get plainTextPrepared(): string {
    if (!this.isValidPlainTextTicket) {
      return 'Error';
    }

    return ((this.ticket && this.ticket.file_url) || '')
      .replace(PLAIN_TEXT_TICKET_PREFIX, '')
      .substring(0, PLAIN_TEXT_TICKET_MAX_LENGTH);
  }

  // N.B. Backend provides '0001-01-01T00:00:00Z' when ticket dates haven't been provided at save time
  public get isDateStartEmpty(): boolean {
    return this.ticket && this.ticket.date_start === '0001-01-01T00:00:00Z';
  }

  public get isDateEndEmpty(): boolean {
    return this.ticket && this.ticket.date_end === '0001-01-01T00:00:00Z';
  }

  public get isTicketValidForWholeEvent(): boolean {
    const eventDateStart: Date = this.event.date_start;
    const eventDateEnd: Date = this.event.date_end;
    const sameStart = (new Date(this.ticket.date_start)).getTime() === eventDateStart.getTime();
    const sameEnd = (new Date(this.ticket.date_end)).getTime() === eventDateEnd.getTime();
    return (this.isDateStartEmpty && this.isDateEndEmpty) || (sameStart && sameEnd);
  }

  public get formattedTicketPeriod(): string {
    if (this.isTicketValidForWholeEvent) {
      return (this.$t('ticketValidForWholeEvent') || '') as string;
    }

    return [
      this.isDateStartEmpty ? null : this.formatDate(new Date(this.ticket.date_start)),
      this.isDateEndEmpty ? null : this.formatDate(new Date(this.ticket.date_end)),
    ].filter(x => x).join(' — ');
  }

  public get ticketLinkType(): string {
    const tUrl = (this.ticket && this.ticket.file_url) || '';
    if (this.isValidPlainTextTicket) {
      return 'Plain text';
    }

    if (this.isTicketUrlInvalid) {
      return 'Bad URL';
    }

    const fileExt = FileHelper.getFileExtension(tUrl).toLowerCase();
    const isUrlOfFile = !!fileExt;
    if (!isUrlOfFile) {
      return 'Webpage';
    }

    if (FileHelper.isImage(tUrl)) {
      return 'Image';
    }

    switch (fileExt) {
      case 'pdf':
        return 'PDF';
      case 'doc':
      case 'docx':
        return 'Word';
      default:
        return 'Webpage';
    }
  }

  public get ticketThumbBackgroundColor(): string {
    switch (this.ticketLinkType) {
      case 'Bad URL':
        return '#c00';
      case 'Word':
        return '#00d';
      case 'Plain text':
        return '#ddd';
      default:
        return UtilsHelper.stringToHSL(this.ticketLinkType);
    }

  }

  public get isTicketUrlInvalid(): boolean {
    const tUrl = (this.ticket && this.ticket.file_url) || '';
    return !tUrl || (/^https?:\/\//.test(tUrl) === false);
  }

  public get isValidPlainTextTicket(): boolean {
    return this.isTicketUrlInvalid;
  }

  public get isTicketUrlOfFile(): boolean {
    return !!FileHelper.getFileExtension((this.ticket && this.ticket.file_url) || '');
  }

  public formatDate(date: Date): string {
    if (!date || !DateTimeHelper.isJSDate(date)) {
      return '';
    }
    return DateTimeHelper.newsPublicationDate(date, this.$i18n.locale);
  }

  public onDeleteTicketClick(): void {
    this.$emit('deleteTicket');
  }

}
