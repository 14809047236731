


import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TGetSingleTicketRequestParams, TTicket} from '@/_api/tickets.api';
import TicketList from '@/_modules/tickets/components/ticket-list/ticket-list.vue';
import {TEvent} from '@/_types/event.type';
import FileHelper from '@/_helpers/file.helper';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import { PLAIN_TEXT_TICKET_PREFIX, PLAIN_TEXT_TICKET_MAX_LENGTH } from '@/_modules/tickets/components/create-ticket/create-ticket.vue';

@Component({
  components: {
    TicketList,
    EwButton,
  }
})
export default class TicketViewer extends Vue {

  @Ref('unpreviewableTicketUrlLinkRef') unpreviewableTicketUrlLinkRef: HTMLAnchorElement;

  @Action('ticketsStore/requestSingleTicket') getTicket: (params: TGetSingleTicketRequestParams) => Promise<TTicket>;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('ticketsStore/singleTicket') singleTicket: TTicket;

  public isLoading: boolean = true;

  public get ticketId(): number {
    return this.$route.params.ticketId ? parseInt(this.$route.params.ticketId, 10) : null;
  }

  public get isTicketIframeEnabled(): boolean {
    if (!this.singleTicket || !this.singleTicket.file_url) {
      return false;
    }
    const tUrl = this.singleTicket.file_url;
    const isHttps = tUrl.indexOf('https://') === 0;
    const isDisplayable = this.isTicketUrlDisplayable();

    return isHttps
      && isDisplayable;
  }

  public isTicketUrlDisplayable(): boolean {
    const extensionBlackList: string[] = [
      'exe', 'com', 'bat', 'apk', 'java', 'cpp', 'rar', 'zip', '7z', 'vbs', 'vb', 'raw',
    ];
    const fileExt = FileHelper.getFileExtension((this.singleTicket && this.singleTicket.file_url) || '');
    return extensionBlackList.indexOf((fileExt || '').toLowerCase()) < 0;
  }

  public get isValidPlainTextTicket(): boolean {
    return !this.isTicketIframeEnabled;
  }

  public get plainTextPrepared(): string {
    if (!this.isValidPlainTextTicket) {
      return 'Error';
    }

    return ((this.singleTicket && this.singleTicket.file_url) || '')
      .replace(PLAIN_TEXT_TICKET_PREFIX, '') // TODO: phase the prefix out
      .substring(0, PLAIN_TEXT_TICKET_MAX_LENGTH);
  }

  @Watch('ticketId', { immediate: true })
  private onTicketIdChange(newVal: number): void {
    if (newVal) {
      this.init();
    }
  }

  public async init(): Promise<void> {
    this.isLoading = true;
    try {
      await this.getTicket({ ticketId: this.ticketId });
    } catch {
      this.$router.push({name: 'event-tickets'});
    }
    this.isLoading = false;
  }

  public onOpenUnpreviewableTicketClick(): void {
    this.unpreviewableTicketUrlLinkRef.click();
  }
}
